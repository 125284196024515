import React from "react"

import Footer from "../components/footer"
import Header from "../components/header"
export default () => {
  return (

  
<div class="min-h-screen bg-white mb-4">
  <Header/>

  <main class="overflow-hidden">

    <div class="bg-gray-50">
      <div class="py-24 lg:py-8">
        <div class="relative z-10 max-w-7xl mx-auto pl-4 pr-8 sm:px-6 lg:px-8">
          <h1 class="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl lg:text-6xl">Get in touch</h1>
          <p class="mt-6 text-xl text-gray-500 max-w-3xl">Questions about our services? Drop us a line!  We provide free estimates for any service and we always like hearing from our customers.</p>
        </div>
      </div>
    </div>

    <section class="relative bg-white" aria-labelledby="contactHeading">
      <div class="absolute w-full h-1/2 bg-gray-50" aria-hidden="true"></div>
      
      <div class="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <svg class="absolute z-0 top-0 right-0 transform -translate-y-16 translate-x-1/2 sm:translate-x-1/4 md:-translate-y-24 lg:-translate-y-72" width="404" height="384" fill="none" viewBox="0 0 404 384" aria-hidden="true">
          <defs>
            <pattern id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="384" fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
        </svg>
      </div>
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="relative bg-white shadow-xl">
          <h2 id="contactHeading" class="sr-only">Contact us</h2>

          <div class="grid grid-cols-1 lg:grid-cols-3">
            
            <div class="relative overflow-hidden py-10 px-6 bg-mvgreen sm:px-10 xl:p-12">
              
              <div class="absolute inset-0 pointer-events-none sm:hidden" aria-hidden="true">
                <svg class="absolute inset-0 w-full h-full" width="343" height="388" viewBox="0 0 343 388" fill="none" preserveAspectRatio="xMidYMid slice" xmlns="http://www.w3.org/2000/svg">
                  <path d="M-99 461.107L608.107-246l707.103 707.107-707.103 707.103L-99 461.107z" fill="url(#linear1)" fill-opacity=".1" />
                  <defs>
                    <linearGradient id="linear1" x1="254.553" y1="107.554" x2="961.66" y2="814.66" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#fff"></stop>
                      <stop offset="1" stop-color="#fff" stop-opacity="0"></stop>
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div class="hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none sm:block lg:hidden" aria-hidden="true">
                <svg class="absolute inset-0 w-full h-full" width="359" height="339" viewBox="0 0 359 339" fill="none" preserveAspectRatio="xMidYMid slice" xmlns="http://www.w3.org/2000/svg">
                  <path d="M-161 382.107L546.107-325l707.103 707.107-707.103 707.103L-161 382.107z" fill="url(#linear2)" fill-opacity=".1" />
                  <defs>
                    <linearGradient id="linear2" x1="192.553" y1="28.553" x2="899.66" y2="735.66" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#fff"></stop>
                      <stop offset="1" stop-color="#fff" stop-opacity="0"></stop>
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div class="hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none lg:block" aria-hidden="true">
                <svg class="absolute inset-0 w-full h-full" width="160" height="678" viewBox="0 0 160 678" fill="none" preserveAspectRatio="xMidYMid slice" xmlns="http://www.w3.org/2000/svg">
                  <path d="M-161 679.107L546.107-28l707.103 707.107-707.103 707.103L-161 679.107z" fill="url(#linear3)" fill-opacity=".1" />
                  <defs>
                    <linearGradient id="linear3" x1="192.553" y1="325.553" x2="899.66" y2="1032.66" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#fff"></stop>
                      <stop offset="1" stop-color="#fff" stop-opacity="0"></stop>
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <h3 class="text-lg font-medium text-white">Contact information</h3>
              <p class="mt-6 text-base text-teal-50 max-w-3xl">P.O. Box 71 | Rimersburg, PA 16248</p>
              <dl class="mt-8 space-y-6">
                <dt><span class="sr-only">Phone number</span></dt>
                <dd class="flex text-center text-4xl text-teal-50">
                  
                  <svg class="flex-shrink-0 w-12 h-12 mt-2 text-teal-200" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                  </svg>
                  <span class="ml-3">(814) 221-7210</span>
                </dd>
                
              </dl>
              <ul class="mt-8 flex space-x-12" role="list">
                <li>
                  <a class="text-teal-200 hover:text-teal-100" href="https://www.facebook.com/MVPropertyCare/">
                    <span class="sr-only">Facebook</span>
                    <svg class="w-7 h-7" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                      <path fill-rule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clip-rule="evenodd" />
                    </svg>
                  </a>
                </li>
                
                <li>
                  <a class="text-teal-200 hover:text-teal-100" href="https://twitter.com/mvpropertycare">
                    <span class="sr-only">Twitter</span>
                    <svg class="w-7 h-7" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                      <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                    </svg>
                  </a>
                </li>
              </ul>
            </div>

            
            <div class="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
              <h3 class="text-lg font-medium text-gray-900">Send us a message</h3>
              <form data-netlify="true" class="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                <div>
                  <label for="first_name" class="block text-sm font-medium text-gray-900">First name</label>
                  <div class="mt-1">
                    <input type="text" name="first_name" id="first_name" autocomplete="given-name" class="border-2 py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-mvteal focus:border-mvteal border-gray-300 rounded-md"></input>
                  </div>
                </div>
                <div>
                  <label for="last_name" class="block text-sm font-medium text-gray-900">Last name</label>
                  <div class="mt-1">
                    <input type="text" name="last_name" id="last_name" autocomplete="family-name" class="border-2 py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-mvteal focus:border-mvteal border-gray-300 rounded-md"></input>
                  </div>
                </div>
                <div>
                  <label for="email" class="block text-sm font-medium text-gray-900">Email</label>
                  <div class="mt-1">
                    <input id="email" name="email" type="email" autocomplete="email" class="border-2 py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-mvteal focus:border-mvteal border-gray-300 rounded-md"></input>
                  </div>
                </div>
                <div>
                  <div class="flex justify-between">
                    <label for="phone" class="block text-sm font-medium text-gray-900">Phone</label>
                    <span id="phone-optional" class="text-sm text-gray-500">Optional</span>
                  </div>
                  <div class="mt-1">
                    <input type="text" name="phone" id="phone" autocomplete="tel" class="border-2 py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-mvteal focus:border-mvteal border-gray-300 rounded-md" aria-describedby="phone-optional"></input>
                  </div>
                </div>
                <div class="sm:col-span-2">
                  <label for="subject" class="block text-sm font-medium text-gray-900">Subject</label>
                  <div class="mt-1">
                    <input type="text" name="subject" id="subject" class="border-2 py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-mvteal focus:border-mvteal border-gray-300 rounded-md"></input>
                  </div>
                </div>
                <div class="sm:col-span-2">
                  <div class="flex justify-between">
                    <label for="message" class="block text-sm font-medium text-gray-900">Message</label>
                    <span id="message-max" class="text-sm text-gray-500">Max. 500 characters</span>
                  </div>
                  <div class="mt-1">
                    <textarea id="message" name="message" rows="4" class="border-2 py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-mvteal focus:border-mvteal border border-gray-300 rounded-md" aria-describedby="message-max"></textarea>
                  </div>
                </div>
                <div class="sm:col-span-2 sm:flex sm:justify-end">
                  <button type="submit" class="mt-2 w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-mvteal hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-mvteal sm:w-auto">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>

  </main>

  <Footer/>
</div>
 
    
  )
}
